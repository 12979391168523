.sandbox .header {
  width: 100%;
  background-color: #f9f9f9;
  height: 70px;
  margin-top: 60px;
}

.sandbox .input-column {
  width: 18%;
  min-width: 200px;
  margin: 10px;
}

.sandbox .empty-column {
  width: 18%;
  min-width: 200px;
  margin: 10px;
}


.sandbox .balance-container {
  color: #FFFFFF;
  border-radius: 4px;
}

.sandbox .table-column Table > tbody > tr > td:last-child,
.sandbox .table-column Table > thead > tr > th:last-child {
  text-align: end;
}

.sandbox .sandbox .panel-content {
  overflow: auto;
}

.sandbox .panel-heading {
  background-color: #25282A !important;
}

.sandbox .panel-content tbody td {
  border: 0;
}

.sandbox .error-field {
  border: 2px solid #FF0000;
  border-radius: 7px;
}

.sandbox .error-message {
  font-size: 14px;
  color: red;
  margin-top: 2px;
}

[type="file"] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 44px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

[type="file"] + label {
  position: relative;
  font-weight: normal;
  border: 1px solid #D1D1D1;
  height: 44px;
  border-radius: 5px;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  padding: 10px;
  font-size: 14px;
  margin: 0;
}

.browse {
  position: absolute;
  top: 8px;
  right: 10px;
  border: 1px solid #D1D1D1;
  padding: 2px 5px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  width: 80px;
}

@media screen and (max-width:500px) {
  .sandbox .empty-column {
    display: none !important;
  }
}
